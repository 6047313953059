<template>
  <div class="auth-form">
    <template v-if="!passwordResetSuccess">
      <form @submit.prevent="formSubmit">
        <fieldset>
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">New Password</div>
              <input-block type="password"
                           name="password"
                           :min="8"
                           :max="50"
                           bg-is-transparent
                           :input-value="userFormData.password"
                           @inputChanged="inputChanged($event, 'userFormData', 'password')"
                           :error-message="errors?.password ?? null"
                           password-trigger
                           placeholder="8+, 1 number + capital letter"
                           required></input-block>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col">
              <div class="form-label">Repeat Password</div>
              <input-block type="password"
                           name="password"
                           :min="8"
                           :max="50"
                           bg-is-transparent
                           :input-value="userFormData.password_confirmation"
                           @inputChanged="inputChanged($event, 'userFormData', 'password_confirmation')"
                           :error-message="errors?.password_confirmation ?? null"
                           password-trigger
                           placeholder="Repeat your password"
                           required></input-block>
            </div>
          </div>

          <div class="form-row mar-none">
            <div class="form-col">
              <div class="response-msg-block-wrap"></div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <button type="submit"
                      class="btn btn-default btn-rounded sign-in-btn">Change password
              </button>
            </div>
          </div>

          <div class="form-row notification">
            <div class="form-col">
              If you continue to have problems resetting your password, contact us at <a
                href="mailto:support@asolytics.com"
                class="link">support@asolytics.com</a>
            </div>
          </div>

        </fieldset>
      </form>
    </template>

    <template v-else>
      <div class="password-reset-success-msg">
        <div class="emoji">🙌</div>
        <div class="success-msg">Password was changed</div>
        <button type="button"
                class="btn btn-default btn-rounded"
                @click="loginClickHandler">Login
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import InputBlock from "@/components/Forms/InputBlock/index";

export default {
  name: "ResetPasswordConfirmationForm",
  data() {
    return {
      userFormData: {
        password: '',
        password_confirmation: '',
      },
      errors: {},
      passwordResetSuccess: false
    }
  },
  components: {
    'input-block': InputBlock,
  },
  methods: {
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    async formSubmit() {
      const formdata = new FormData();
      formdata.append("email", this.userFormData.email);
      formdata.append("password", this.userFormData.password);
      formdata.append("type", 'tokenized');
      formdata.append("from_url", window.location.origin + this.$route?.path);
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + 'auth/login', formdata);

      //TODO after successfull ajax change this into if/else block
      this.passwordResetSuccess = true;
      if (result.token && !result?.errors) {
        // this.passwordResetSuccess = true;
      } else {
        this.errors = {...result.errors};
      }
    },
    loginClickHandler() {
      this.$emit('form-clicked', 'LoginForm');
    }
  },
}
</script>