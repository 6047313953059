<template>
  <main class="full-width auth-page">
    <the-new-header>
      <template v-slot:main-block>&nbsp;</template>
    </the-new-header>
    <div class="authorization-page">
      <div class="auth-content-block"
           :class="[{'reset-password-form': currentComponent === 'ResetPasswordConfirmationForm'}]">
        <div class="form-wrap">
          <div class="auth-form">
            <template v-if="!passwordResetSuccess">
              <div class="reset-password-title">
                Reset Password
              </div>
              <form @submit.prevent="formSubmit">
                <fieldset>
                  <div class="form-row">
                    <div class="form-col">
                      <div class="form-label">New Password</div>
                      <input-block type="password"
                                   name="password"
                                   :min="8"
                                   :max="50"
                                   bg-is-transparent
                                   :input-value="userFormData.password"
                                   @inputChanged="inputChanged($event, 'userFormData', 'password')"
                                   :error-message="errors?.password ?? null"
                                   password-trigger
                                   placeholder="8+, 1 number + capital letter"
                                   required></input-block>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-col">
                      <div class="form-label">Repeat Password</div>
                      <input-block type="password"
                                   name="password"
                                   :min="8"
                                   :max="50"
                                   bg-is-transparent
                                   :input-value="userFormData.password_confirmation"
                                   @inputChanged="inputChanged($event, 'userFormData', 'password_confirmation')"
                                   :error-message="errors?.password_confirmation ?? null"
                                   password-trigger
                                   placeholder="Repeat your password"
                                   required></input-block>
                    </div>
                  </div>

                  <div class="form-row mar-none">
                    <div class="form-col">
                      <div class="response-msg-block-wrap"></div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-col">
                      <button type="submit"
                              class="btn btn-default btn-rounded sign-in-btn">Change password
                      </button>
                    </div>
                  </div>

                  <div class="form-row notification">
                    <div class="form-col">
                      If you continue to have problems resetting your password, contact us at <a
                        href="mailto:support@asolytics.com"
                        class="link">support@asolytics.com</a>
                    </div>
                  </div>

                </fieldset>
              </form>
            </template>

            <template v-else>
              <div class="password-reset-success-msg">
                <div class="emoji">🙌</div>
                <div class="success-msg">Password was changed</div>
                <button type="button"
                        class="btn btn-default btn-rounded"
                        @click="loginClickHandler">Login
                </button>
              </div>
            </template>
          </div>
        </div>

        <div class="auth-page-image">
          <img src="@/assets/images/login-illustration.svg"
               width="552"
               alt="Login illustration">
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import ResetPasswordConfirmationForm from '@/components/Forms/ResetPasswordConfirmationForm/index.vue';
import InputBlock from "@/components/Forms/InputBlock/index";
import {httpRequest} from "@/api";
import TheNewHeader from "@/components/TheNewHeader/index.vue";

export default {
  name: "ResetPassword",
  components: {
    TheNewHeader,
    ResetPasswordConfirmationForm,
    'input-block': InputBlock,
  },
  data() {
    return {
      currentComponent: 'ResetPasswordConfirmationForm',
      registrationEmail: '',
      name: '',
      resetToken: '',
      resetEmail: '',
      userFormData: {
        password: '',
        password_confirmation: '',
      },
      errors: {},
      passwordResetSuccess: false
    }
  },
  mounted() {
    this.resetToken = this.$route.query?.token;
    this.resetEmail = this.$route.query?.email;

    if (!this.resetEmail || !this.resetToken) {
      this.$router.push('/');
    }
  },
  methods: {
    tabClickHandler(data) {
      this.currentComponent = data.componentName;
      if (data.email) {
        this.registrationEmail = data.email;
      }
    },
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    async formSubmit() {
      const formdata = new FormData();
      formdata.append("email", this.resetEmail);
      formdata.append("token", this.resetToken);
      formdata.append("password", this.userFormData?.password);
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("password_confirmation", this.userFormData?.password_confirmation);
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + 'auth/reset-password', formdata);
      if (result.status && result.status === 'passwords.reset') {
        this.passwordResetSuccess = true;
      } else if (result.status && result.status === 'passwords.user') {
        this.errors = {
          password: 'Unfortunately, we were unable to find this email.',
          password_confirmation: 'Unfortunately, we were unable to find this email.',
        };
      } else if (result.status && result.status === 'passwords.throttled') {
        this.errors = {
          password: 'Please wait before retrying.',
          password_confirmation: 'Please wait before retrying.',
        };
      } else if (result.status && result.status === 'passwords.token') {
        this.errors = {
          password: 'This password reset token is invalid.',
          password_confirmation: 'This password reset token is invalid.',
        };
      } else {
        this.errors = {...result?.errors};
      }
    },
    loginClickHandler() {
      this.$router.push('/login');
    }
  },

}
</script>

<style lang="scss" src="./styles.scss"></style>